import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { List } from "flowbite-react";
import i18n from "./i18n";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#38AECC",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { t } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      {i18n.language === "pt" ? (
        <>
          {" "}
          <Container>
            <Typography
              variant="h3"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
                mt: "1rem",
              }}
            >
              {t("termsAndConditions")}
            </Typography>
            <Typography
              variant="h6"
              component="div"
              sx={{ textAlign: "center", float: "center", mt: "1rem" }}
            >
              <b>
                Escritório rua do cano, 9 , 9100-109 santa cruz horário de
                funcionamento aberto desde as 8:30 até as 18:30 {<br />} Escritório
                Rua ivens N 12 , 9000-046 funchal horário de funcionamento aberto
                desde as 8:30 até as 18:30
              </b>
            </Typography>
          </Container>
          <Container
            sx={{
              mt: "1rem",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
              }}
            >
              Serviço fora do horário de funcionamento aeroporto
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{
                flexGrow: 1,
                textAlign: "justify",
                mt: "1rem",
                mr: "5rem",
                ml: "5rem",
              }}
            >
              Para cada levantamento a partir das <b>18:30 até 23:59</b> Ou
              devolução a partir das <b>00:00 até 08:29</b> Uma taxa de{" "}
              <b style={{ color: "blue" }}>€35,00</b> Será cobrada diretamente ao
              cliente.
              {<br />} No caso de atrasos no voo provocando esta situação, a <b>Taxa será aplicada</b>.{<br />}
              O levantamento da viatura após as <b>00:00 até 08:29</b> Uma taxa de{" "}
              <b style={{ color: "blue" }}>€ 55,00</b> Será cobrada.
              {<br />}
              Para levantar a viatura fora do horário de funcionamento terá que sempre <b>solicitar</b>.
            </Typography>
          </Container>
          <Container
            sx={{
              mt: "1rem",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
              }}
            >
              Taxas / Extras
            </Typography>
            <List>
              <List.Item>
                <b>Cadeira de bebê isofix:</b> Também pode ser fornecido
                mediante solicitação. O Custo será{" "}
                <b style={{ color: "blue" }}> € 10,00</b> Por dia em um máximo
                de <b style={{ color: "blue" }}>€ 90,00</b> Por aluguer.
              </List.Item>
              <List.Item>
                <b>Cadeira para bebês:</b> Também pode ser fornecido mediante
                solicitação. O custo será{" "}
                <b style={{ color: "blue" }}>€ 5,00</b> Por dia em um máximo de{" "}
                <b style={{ color: "blue" }}>€ 60,00</b> Por aluguer.
              </List.Item>
              <List.Item>
                <b>Cadeira elevatória:</b> Também pode ser fornecido mediante
                solicitação. O custo sera{" "}
                <b style={{ color: "blue" }}>€ 2,00</b> Por Dia em um máximo de{" "}
                <b style={{ color: "blue" }}>€ 28,00</b> Por Aluguer.
              </List.Item>
              <List.Item>
                <b>Condutor joven: </b> Todos os condutores entre 21 e 25
                anos(inclusive) devem pagar uma taxa adicional
                de {""}
                <b style={{ color: "blue" }}>€ 35,00</b> Por aluguer.
              </List.Item>
              <List.Item>
                <b> Condutor adicional: </b> Para cada
                condutor adicional mencionado no contrato de aluguer, uma taxa de 
                <b style={{ color: "blue" }}>€20,00</b> Será cobrada.
              </List.Item>
              <List.Item>
                <b>FERRY PORTO SANTO:</b>Também pode ser autorizado mediante
                solicitação com um custo de
                <b style={{ color: "blue" }}>€ 60,00</b> Por aluguer.
              </List.Item>
            </List>
          </Container>
          <Container>
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
                mt: "1rem",
              }}
            >
              <i>
                Para ser coberto pelo seguro, os condutores devem ser
                devidamente mencionados no contrato de aluguer.
              </i>
            </Typography>
            <Container sx={{ mt: "1rem" }}>
              <List>
                <List.Item>
                  <b> Devolução com antecedência: </b> não iremos
                  Reembolsar o cliente nem a agência envolvida na Reserva pelos
                  dias de aluguer que não foram usados.
                </List.Item>
                <List.Item>
                  <b>
                    {" "}
                    Não comparecer / não aceitar os termos acordados:{" "}
                  </b>{" "}
                  todos os dias de aluguer reservados serão faturados em caso de
                  não comparecer ou se o cliente não atender os requisitos de
                  aluguer.
                </List.Item>
                <List.Item>
                  <b> Alteração de reserva: </b> para qualquer alteração 48h
                  antes da entrega da viatura, uma taxa de{" "}
                  <b style={{ color: "blue" }}> € 30,00</b> Será cobrada
                  diretamente ao cliente.
                </List.Item>
              </List>
            </Container>
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
                mt: "1rem",
              }}
            >
              <i>Muito importante</i>
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              O cliente é sempre responsável por um franquia
              na quantidade de {""}
              <b style={{ color: "blue" }}>€ 1.000,00</b> para <b>GRUPO A</b>,{" "}
              <b style={{ color: "blue" }}>€ 1.500,00</b> para{" "}
              <b>Grupos C, D and J </b>
              <b style={{ color: "blue" }}>€ 2.000,00</b> para{" "}
              <b>Grupos K, K1 and L</b>,{" "}
              <b style={{ color: "blue" }}>€ 3.000,00</b> para{" "}
              <b>Grupos F, M and M1</b> No caso de um acidente auto-provocado,
              em Caso de danos causados ​​por atos de vandalismo, caso o roubo
              do Veículo ou roubo de qualquer um de seus acessórios, desde que o
              O motorista é incapaz de indentificar o partido culpado.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              Este depósito de segurança deve ser feito na entrega do carro por
              CARTÃO DE CRÉDITO.
            </Typography>
            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              O depósito de segurança referido será reembolsado ao cliente no
              fim do aluguer, caso nenhum acidente tenha <b> ocorrido </b>
              Bem como nenhum dano causado ao veículo por atos de
              Vandalismo, roubo do veículo ou de qualquer um de seus acessórios.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              Esta situação só pode ser eliminada com o pagamento de {""}
              <b> Super C.D.W. (Seguro todos os riscos) </b>
              DE <b style={{ color: "blue" }}>€ 10,00 </b>Por dia para{" "}
              <b>Grupos A</b>, <b style={{ color: "blue" }}>€ 15,00</b> Por dia
              Para <b> grupos C, D e J</b>,{" "}
              <b style={{ color: "blue" }}>€ 20,00</b> PARA{" "}
              <b>Grupos K, K1 and L</b>,{" "}
              <b style={{ color: "blue" }}>€ 30,00</b> PARA{" "}
              <b>Grupos F, M and M1</b> Este valor deve ser pago pelo cliente Ao
              receber o veículo {""}
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              O seguro acima não elimina os danos ou atos de Vandalismo
              mencionado abaixo: os clientes serão responsabilizados por Danos
              infligidos a pneus, óculos, jantes, espelhos externos,
              chassis e atos de vandalismo por desconhecido ou
              não. Essa responsabilidade pode ser eliminada pela taxa de
              cobertura extra de
              <b style={{ color: "blue" }}> € 5,00</b>
              Por dia para <b> grupos A, C, D e J </b>,{" "}
              <b style={{ color: "blue" }}>€ 10,00</b> Por dia para{" "}
              <b>Grupos para grupos K, K1 e L</b>,{" "}
              <b style={{ color: "blue" }}>€ 15,00</b> FOR{" "}
              <b>Grupos F, M e M1 </b> a serem pagos pelo cliente no momento do levantamento {""}
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              <b> Exclusões: </b> Uso negligente / Violações de trafico como
              multas de estacionamento, perda de chaves, erro de combustível,
              interior do veículo, embreagem e caixa de velocidades.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              Em caso de acidente pela culpa do condutor de carro, levando em
              Consideração da lei portuguesa, as companhias de seguros recusam
              qualquer ou Toda responsabilidade por esse condutor, de todos
              os danos causados ​​ao Veículo alugado ou para terceiros sempre
              que é detectado que o condutor estava dirigindo sob a influência
              de álcool, narcóticos, Drogas ou produtos tóxicos ou por
              insanidade do condutor. Também se o o cliente usa o carro em
              competições desportivas, corridas, rallys ou desafios, concursos,
              apostas, etc.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              Também aproveitamos esta oportunidade para informar que, mesmo que
              o condutor não esteja sob a influência de nenhum dos itens
              acima, mas por Qualquer meio também destrói o carro por culpa
              própria, não iremos Reembolsar qualquer quantia para o tempo
              restante para a conclusão do aluguer. Apenas é possivel fornecer outro carro
              através da realização de uma nova fránquia que pode cobrir Eventuais danos que
              podem ser causados ​​ao carro novo.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem", mb: "1rem" }}
            >
              Todas as reservas que chegam ao nosso departamento de reservas com menos de 3 dias de antecendencia serão confirmadas
              mediante solicitação.
            </Typography>
          </Container>
        </>
      ) : (
        <>
          {" "}
          <Container>
            <Typography
              variant="h3"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
                mt: "1rem",
              }}
            >
              {t("termsAndConditions")}
            </Typography>
            <Typography
              variant="h6"
              component="div"
              sx={{ textAlign: "center", float: "center", mt: "1rem" }}
            >
              <b>
                OFFICE ADDRESS RUA DO CANO, 9 , 9100-109 SANTA CRUZ WORKING
                HOURS ARE FROM 8:30AM TO 06:30PM {<br />} OFFICE ADDRESS RUA
                IVENS N12 , 9000-046 FUNCHAL WORKING HOURS ARE FROM 8:30AM TO
                06:30PM
              </b>
            </Typography>
          </Container>
          <Container
            sx={{
              mt: "1rem",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
              }}
            >
              AIRPORT SERVICE OUTSIDE OF OPENING HOURS
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{
                flexGrow: 1,
                textAlign: "justify",
                mt: "1rem",
                mr: "5rem",
                ml: "5rem",
              }}
            >
              FOR EACH PICK UP FROM <b>06:31pm TO 11:59pm</b> OR DROP OFF FROM{" "}
              <b>00:00pm TO 08:29</b> A FEE OF{" "}
              <b style={{ color: "blue" }}>€35,00</b> WILL BE CHARGED DIRECTLY
              TO THE CUSTOMER.
              {<br />} IN THE EVENT OF FLIGHT DELAYS CAUSING THIS SITUATION, OUT
              OF HOURS <b>FEE WILL APPLY</b>.{<br />}
              PICK UP FROM <b>00:00am TO 08:29am</b> A FEE OF{" "}
              <b style={{ color: "blue" }}>€ 60,00</b> WILL BE CHARGED.
              {<br />}
              PICK UP OUTSIDE OF OPENING HOURS ALWAYS UPON <b>REQUEST</b>.
            </Typography>
          </Container>
          <Container
            sx={{
              mt: "1rem",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
              }}
            >
              EXTRA FEES
            </Typography>
            <List>
              <List.Item>
                <b>BABY SEATS ISOFIX:</b> MAY ALSO BE SUPPLIED ON REQUEST. THE
                COST FOR THESE WILL BE <b style={{ color: "blue" }}> € 10,00</b>{" "}
                PER DAY ON A MAXIMUM OF <b style={{ color: "blue" }}>€ 90,00</b>{" "}
                PER RENTAL.
              </List.Item>
              <List.Item>
                <b>BABY SEATS:</b> MAY ALSO BE SUPPLIED ON REQUEST. THE COST FOR
                THESE WILL BE <b style={{ color: "blue" }}>€ 5,00</b> PER DAY ON
                A MAXIMUM OF <b style={{ color: "blue" }}>€ 60,00</b> PER
                RENTAL.
              </List.Item>
              <List.Item>
                <b>BOOSTER SEAT:</b> MAY ALSO BE SUPPLIED ON REQUEST. THE COST
                FOR THESE WILL BE <b style={{ color: "blue" }}>€ 2,00</b> PER
                DAY ON A MAXIMUM OF <b style={{ color: "blue" }}>€ 28,00</b> PER
                RENTAL.
              </List.Item>
              <List.Item>
                <b>YOUNG DRIVERS:</b> ALL DRIVERS BETWEEN 21 AND 25 YEARS OLD
                MUST PAY DIRECTLY TO WHY NOT AN ADDITIONAL TAX OF{" "}
                <b style={{ color: "blue" }}>€ 35,00</b> PER RENTAL.
              </List.Item>
              <List.Item>
                <b>ADDITIONAL DRIVER:</b> SECOND DRIVER INCLUDED. FOR EACH
                ADDITIONAL DRIVER MENTIONED ON THE RENTAL AGREEMENT A FEE OF
                <b style={{ color: "blue" }}>€20,00</b> WILL BE CHARGED.
              </List.Item>
              <List.Item>
                <b>FERRY PORTO SANTO:</b> MAY ALSO BE AUTHORISED ON REQUEST WITH
                A COST OF <b style={{ color: "blue" }}>€ 60,00</b> PER RENTAL.
                ONE WAY IS NOT ALLOWED.
              </List.Item>
            </List>
          </Container>
          <Container>
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
                mt: "1rem",
              }}
            >
              <i>
                To be covered by Insurance, Drivers must be duly mentioned on
                the Rental Agreement.
              </i>
            </Typography>
            <Container sx={{ mt: "1rem" }}>
              <List>
                <List.Item>
                  <b>COLLECTING IN ADVANCE:</b> THROUGH THIS RATE WE SHALL NOT
                  REFUND EITHER THE CLIENT NOR THE AGENCY INVOLVED IN THE
                  BOOKING FOR THE TOTAL OR PARCIAL RENTAL DAYS WHICH WERE NOT
                  USED.
                </List.Item>
                <List.Item>
                  <b>NO SHOW / NON-COMPLIANCE WITH THE AGREED TERMS:</b> ALL
                  BOOKED RENTAL DAYS WILL BE INVOICED IN CASE OF NO SHOW OR IF
                  CLIENT DOES NOT MEET RENTAL REQUIREMENTS.
                </List.Item>
                <List.Item>
                  <b>BOOKING ALTERATION:</b> FOR ANY ALTERATION 48H PRIOR TO CAR
                  DELIVERY, A FEE OF <b style={{ color: "blue" }}> € 30,00</b>{" "}
                  WILL BE CHARGED DIRECTLY TO THE CLIENT.
                </List.Item>
              </List>
            </Container>
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
                mt: "1rem",
              }}
            >
              <i>Very Important</i>
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              THE CLIENT (WHETHER DRIVER OR NOT) IS ALWAYS RESPONSIBLE FOR AN
              EXCESS ON THE AMOUNT OF{" "}
              <b style={{ color: "blue" }}>€ 1.000,00</b> FOR <b>GROUP A</b>,{" "}
              <b style={{ color: "blue" }}>€ 1.500,00</b> FOR{" "}
              <b>GROUPS C, D and J </b>
              <b style={{ color: "blue" }}>€ 2.000,00</b> FOR{" "}
              <b>GROUPS K, K1 and L</b>,{" "}
              <b style={{ color: "blue" }}>€ 3.000,00</b> FOR{" "}
              <b>GROUPS F, M and M1</b> IN CASE OF A SELF-PROVOKED ACCIDENT, IN
              CASE OF DAMAGES CAUSED BY ACTS OF VANDALISM, IN CASE THEFT OF THE
              VEHICLE OR OF THEFT OF ANY OF ITS ACCESSORIES, AS LONG AS THE
              DRIVER IS UNABLE TO INDENTIFY THE GUILTY PARTY.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              THIS SECURITY DEPOSIT MUST BE MADE UPON THE DELIVERY OF THE CAR BY
              CREDIT CARD.
            </Typography>
            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              THE REFERRED SECURITY DEPOSIT WILL BE REFUNDED TO THE CLIENT AT
              THE END OF THE RENTAL, IN CASE <b>NO</b> ACCIDENT HAS TAKEN PLACE,
              AS WELL AS NO DAMAGES HAVE BEEN CAUSED TO THE VEHICLE BY ACTS OF
              VANDALISM, THEFT OF THE VEHICLE OR OF ANY OF ITS ACCESSORIES.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              THIS SITUATION CAN ONLY BE ELIMINATED WITH THE PAYMENT OF{" "}
              <b>SUPER C.D.W.</b>
              OF <b style={{ color: "blue" }}>€ 10,00 </b>PER DAY FOR{" "}
              <b>GROUPS A</b>, <b style={{ color: "blue" }}>€ 15,00</b> PER DAY
              FOR <b>GROUPS C, D and J</b>,{" "}
              <b style={{ color: "blue" }}>€ 20,00</b> FOR{" "}
              <b>GROUPS K, K1 and L</b>,{" "}
              <b style={{ color: "blue" }}>€ 30,00</b> FOR{" "}
              <b>GROUPS F, M and M1</b> THIS AMOUNT HAS TO BE PAID BY THE CLIENT
              WHEN RECEIVING THE VEHICLE ALWAYS ON{" "}
              <b style={{ color: "red" }}>A MINIMUM CHARGE BASIS OF 3 DAYS</b>.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              THE ABOVE COVER DOES NOT ELIMINATE THE DAMAGES OR ACTS OF
              VANDALISM MENTIONED BELLOW: CLIENTS WILL BE HELD RESPONSIBLE FOR
              DAMAGES INFLICTED TO TIRES, GLASSES, WHEEL RIMS, EXTERIOR MIRRORS,
              UNDERCARRIAGE AND ACTS OF VANDALISM BY UNKNOWN OR NOT. THIS
              RESPONSIBILITY CAN BE ELIMINATED BY THE EXTRA COVERAGE FEE OF
              <b style={{ color: "blue" }}> € 5,00</b>
              PER DAY FOR <b>GROUPS A, C, D and J</b>,{" "}
              <b style={{ color: "blue" }}>€ 10,00</b> PER DAY FOR{" "}
              <b>GROUPS FOR GROUPS K, K1 and L</b>,{" "}
              <b style={{ color: "blue" }}>€ 15,00</b> FOR{" "}
              <b>GROUPS F, M and M1</b> TO BE PAID BY THE CLIENT DIRECTLY TO WHY
              NOT CAR RENTAL ON{" "}
              <b style={{ color: "red" }}> A MINIMUM CHARGE BASIS OF 3 DAYS.</b>
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              <b>EXCLUSIONS:</b> NEGLIGENT USE / TRAFIC VIOLATIONS AS PARKING
              FINES, KEYS LOSS, FUEL ERROR, VEHICULE INTERIOR, CLUTCH AND GEAR
              BOX.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              IN CASE OF ACCIDENT BY OUR CAR DRIVER'S FAULT TAKING INTO
              CONSIDERATION PORTUGUESE LAW, INSURANCE COMPANIES DECLINE ANY OR
              ALL RESPONSIBILITY FOR THAT DRIVER OF ALL DAMAGES CAUSED TO THE
              HIRED VEHICLE OR TO THIRD PARTIES WHENEVER IT IS DETECTED THAT THE
              DRIVER WAS DRIVING UNDER THE INFLUENCE OF ALCOHOL, NARCOTICS,
              DRUGS OR TOXIC PRODUCTS OR BY INSANITY OF THE DRIVER. ALSO IF THE
              CLIENT USES THE CAR IN SPORTIVE COMPETITIONS, RACES, RALLYS OR
              CHALLENGES, CONTESTS, BETS, ETC.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
            >
              WE ALSO TAKE THIS OPPORTUNITY TO INFORM THAT EVEN IF THE CAR'S
              DRIVER IS NOT UNDER THE INFLUENCE OF ANY OF THE ABOVE ITEMS BUT BY
              ANY MEANS ALSO DESTROYS OUR CAR BY HIS OWN FAULT WE SHALL NOT
              REFUND ANY AMOUNT FOR THE TIME LEFT TO COMPLETE THE RENTAL AND WE
              ONLY GIVE ANOTHER CAR THROUGH A LARGE DEPOSIT THAT MAY COVER
              EVENTUAL DAMAGES THAT CAN BE CAUSED TO THE NEW CAR. WE WOULD LIKE
              CLIENTS TO BE WELL INFORMED ABOUT THE ABOVE SITUATION.
            </Typography>

            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem", mb: "1rem" }}
            >
              ALL BOOKINGS ARRIVING AT OUR HEAD OFFICE-BOOKING DEPARTMENT WITH
              LESS THAN 3 DAYS TO DELIVERY TIME WILL BE CONFIRMED ON REQUEST.
            </Typography>
          </Container>
        </>
      )}
    </ThemeProvider>
  );
};

export default TermsAndConditions;
