import React from 'react';
import { Footer } from 'flowbite-react';
import { BsFacebook, BsInstagram } from 'react-icons/bs';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import Logo from '../../Assets/logo.png';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

function FooterComponent() {
  const { t } = useTranslation();
  return (
    <Footer container>
      <div className="w-full">
        <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
          <div>
            <img
              alt='logo'
              className='h-18'
              width={200} height={100}
              src={Logo}/>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 sm:gap-6">
          <div>
              <Footer.Title title={t("legal")} />
              <Footer.LinkGroup col>
                <Link to="/termsAndConditions"><GavelOutlinedIcon/> {t("termsAndConditions")}</Link >
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title={t("informations")} />
              <Footer.LinkGroup col>
                <Footer.Link target="_blank" href="https://maps.app.goo.gl/HVeD5HaoV7fVrho17"><LocationOnOutlinedIcon/> Rua do Cano Nº9 <br/> 9100-101 Santa Cruz Madeira</Footer.Link>
                  <Footer.Link target="_blank" href="https://maps.app.goo.gl/tuS2fxDxfcjDg1MT7"> <LocationOnOutlinedIcon/> Rua Ivens Nº12 <br/> 9000-046 Funchal</Footer.Link>
                <Footer.Link> <CalendarMonthOutlinedIcon/> {t("monday")} - {t("sunday")} <br/> 08h30 - 18h00</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title={t("contacts")} />
              <Footer.LinkGroup col>
                <Footer.Link href={'mailto:reservations@whynotcarrental.com?subject=Car Rental Quote&body=Quote%20goes%20here'}>
                <EmailOutlinedIcon/>reservations@whynotcarrental.com</Footer.Link>
                <Footer.Link href={'mailto:front.office@whynotcarrental.com?subject=Car Rental Quote&body=Quote%20goes%20here'}>
                <EmailOutlinedIcon/>front.office@whynotcarrental.com</Footer.Link>
                <Footer.Link target="_blank" href="tel:00351291213100">
                  <LocalPhoneOutlinedIcon/>+351 291213100</Footer.Link>
                  <Footer.Link target="_blank" href="https://wa.me/351967762981">
                  <LocalPhoneOutlinedIcon/>+351 967762981</Footer.Link>
              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between backgroundColor=#F4F4F4 ">
          <Footer.Copyright by="WhyNotCarRental" year={2024} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon aria-label="Visit our facebook page" href="https://www.facebook.com/WHYNOTCARRENTAL" icon={BsFacebook} />
            <Footer.Icon aria-label="Visit our instagram page" href="https://www.instagram.com/whynot_carrental?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" icon={BsInstagram} />
          </div>
        </div>
      </div>
    </Footer>
  );
}
export default FooterComponent;