import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Box, Typography, Button, FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DateField, DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PropTypes, { checkPropTypes } from "prop-types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InformationModal from "../Components/Modal/InformationModal";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#202A67",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

function ClientForm({
  reservationInfo,
  setWasClientDetailsFiled,
  setClientDetails,
  pickUpDesk,
  dropOffDesk,
  setYoungDriverClient,
  youngDriverClient,
  setVehicleExtras,
  vehicleExtras
}) {
  const { t } = useTranslation();
  const clientValidation = yup.object({
    customer_name: yup
      .string()
      .required("Required")
      .matches(/^[aA-zZ\s]+$/, t("onlyString")),
    customer_email: yup.string().required(t("mandatory")),
    customer_telephone: yup.number().required(t("mandatory")),
    countryCode: yup.number().required(t("mandatory")).min(1, t("validCountryCode")),
    customer_birthdate: yup
      .date()
      .required(t("mandatory"))
      .max(
        dayjs().subtract(21, "years").startOf("day"),
        t("moreThan18YearsOld")
      ),
    customer_address: yup.string().required(t("mandatory")),
    customer_city: yup
      .string()
      .required(t("mandatory"))
      .matches(/^[aA-zZ\s]+$/, t("onlyString")),
    customer_postalcode: yup.string().required(t("mandatory")),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getFieldState,
  } = useForm({
    defaultValues: {
      customer_name: "",
      customer_email: "",
      customer_telephone: null,
      countryCode: null,
      customer_birthdate: null,
      customer_address: "",
      customer_city: "",
      customer_country: "Portugal",
      customer_postalcode: "0000-000",
      loc_flight: reservationInfo?.loc_flight || "",
    },
    resolver: yupResolver(clientValidation),
    reValidateMode: "onChange",
  });

  const [error, setError] = React.useState(null);
  const [openInformationModal, setOpenInformationModal] = useState(false); // State to control modal visibility

  useEffect(() => {
    window.scrollTo(0, 0)
    if (youngDriverClient) {
      vehicleExtras.extraFees.reduce((acc, extra) => {
        if (youngDriverClient && extra.id === 3) {
          extra.quantity = 1;
          extra.totalValue = 40;
        }

        if (extra.quantity > 0) {
          const extraTotalValue = extra.totalValue;
          acc += extraTotalValue;
        }
        return acc;
      });
    }else{
      vehicleExtras.extraFees.reduce((acc, extra) => {
        if (!youngDriverClient && extra.id === 3) {
          extra.quantity = 0;
          extra.totalValue = 0;
        }

        if (extra.quantity > 0) {
          const extraTotalValue = extra.totalValue;
          acc += extraTotalValue;
        }
        return acc;
      });
    }
  }, [youngDriverClient])

  const getMinimumDate = () => {
    return dayjs().subtract(21, "years").startOf("day");
  };

  const submitData = async (data) => {
    setClientDetails(data);
    setWasClientDetailsFiled(true);
  };

  const handleBirthDate = (date) => {
    
    const age = dayjs().diff(dayjs(date.$d), 'year');

    if(age <= 25){
      setYoungDriverClient(true);
      setOpenInformationModal(true);
    }else{
      setYoungDriverClient(false);
    }

    setValue(
      "customer_birthdate",
      dayjs(date.$d).format("YYYY-MM-DD")
    )
  }

  const handleCloseInformationModal = () => {
    setOpenInformationModal(false);
  };

  const form = useRef();
  return (
    <ThemeProvider theme={theme}>
      <InformationModal
            open={openInformationModal}
            handleClose={handleCloseInformationModal}
            message={t("youngDriverInfo")}
          />
      <Typography
        variant="h4"
        component="div"
        sx={{
          flexGrow: 1,
          color: "primary.main",
          textAlign: "center",
          mt: "1rem",
        }}
      >
        {t("clientDetails")}
      </Typography>
      <Box>
        <form ref={form} onSubmit={handleSubmit(submitData)}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1 },
                width: "80%",
              }}
            >
              <FormControl
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "70%",
                    lg: "70%",
                    xl: "70%",
                  },
                }}
              >
                <TextField
                  required
                  id="customer_name"
                  label={t("fullName")}
                  size="string"
                  type="text"
                  name={t("fullName")}
                  {...register("customer_name", {
                    required: t("fullNameIsRequired"),
                    minLength: {
                      value: 3,
                      message: t("fullNameInvalid"),
                    },
                  })}
                  slotProps={{ textField: { variant: "outlined" } }}
                  error={!!errors.customer_name}
                  onError={(newError) => setError(newError)}
                />
                <FormHelperText error={!!errors.customer_name}>
                  {errors.customer_name?.message}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "30%",
                    lg: "30%",
                    xl: "30%",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    required
                    id="customer_birthdate"
                    label={t("birthDate")}
                    value={getFieldState("customer_birthdate")}
                    onChange={(date) =>
                      handleBirthDate(date)
                    }
                    name="customer_birthdate"
                    format="DD-MM-YYYY"
                    maxDate={getMinimumDate()}
                    slotProps={{ textField: { variant: "outlined" } }}
                    error={!!errors.customer_birthdate}
                    onError={(newError) => setError(newError)}
                  />
                  <FormHelperText error={!!errors.customer_birthdate}>
                    {errors.customer_birthdate?.message}
                  </FormHelperText>
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  required
                  id="customer_address"
                  label={t("address")}
                  size="string"
                  name={t("address")}
                  {...register("customer_address", {
                    required: "Morada é Obrigatoria",
                    minLength: {
                      value: 3,
                      message: "Morada é invalida",
                    },
                  })}
                  helperText={
                    errors.customer_address
                      ? errors.customer_address.message
                      : null
                  }
                />
              </FormControl>
              <FormControl
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "50%",
                    lg: "50%",
                    xl: "50%",
                  },
                }}
              >
                <TextField
                  required
                  id="customer_city"
                  label={t("city")}
                  size="string"
                  name="Cidade"
                  {...register("customer_city", {
                    required: "Cidade é Obrigatoria",
                    minLength: {
                      value: 3,
                      message: "Cidade é invalida",
                    },
                  })}
                  helperText={
                    errors.customer_city ? errors.customer_city.message : null
                  }
                />
              </FormControl>
              <FormControl
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "50%",
                    lg: "50%",
                    xl: "50%",
                  },
                }}
              >
                <TextField
                  required
                  id="customer_email"
                  label={t("Email")}
                  type="email"
                  name="email"
                  {...register("customer_email", {
                    required: t("emailIsRequired"),
                    pattern: {
                      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      message: t("emailInvalid"),
                    },
                  })}
                  helperText={
                    errors.customer_email ? errors.customer_email.message : null
                  }
                />
              </FormControl>
              <FormControl
                sx={{
                  width: {
                    xs: "25%",
                    sm: "25%",
                    md: "30%",
                    lg: "30%",
                    xl: "30%",
                  },
                }}
              >
                <TextField
                  required
                  id="countryCode"
                  label={t("countryCode")}
                  type="number"
                  name={t("countryCode")}
                  {...register("countryCode", {
                    required: t("countryCodeIsRequired"),
                  })}
                  error={!!errors.countryCode}
                  onError={(newError) => setError(newError)}
                  helperText={
                    errors.countryCode ? errors.countryCode.message : null
                  }
                />
              </FormControl>
              <FormControl
                sx={{
                  width: {
                    xs: "75%",
                    sm: "75%",
                    md: "70%",
                    lg: "70%",
                    xl: "70%",
                  },
                }}
              >
                <TextField
                  required
                  id="customer_telephone"
                  label={t("phoneNumber")}
                  type="number"
                  name={t("phoneNumber")}
                  error={!!errors.customer_telephone}
                  onError={(newError) => setError(newError)}
                  {...register("customer_telephone", {
                    required: t("phoneNumberIsRequired"),
                    minLength: {
                      value: 9,
                      message: t("phoneNumberInvalid"),
                    },
                  })}
                  helperText={
                    errors.customer_telephone
                      ? errors.customer_telephone.message
                      : null
                  }
                />
              </FormControl>

              <FormControl
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "50%",
                    lg: "50%",
                    xl: "50%",
                  },
                }}
              >
                <TextField
                  required
                  id="loc_flight"
                  label={
                    pickUpDesk === 47 ||
                    pickUpDesk === 46
                      ? t("hotel")
                      : pickUpDesk === 41
                      ? t("temporaryAddress")
                      : pickUpDesk === 42
                      ? t("flight")
                      : ""
                  }
                  type="text"
                  name="Nº de voo"
                  {...register("loc_flight", {
                    required: t("mandatory"),
                  })}
                  helperText={
                    errors.loc_flight ? errors.loc_flight.message : null
                  }
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "1rem",
                }}
              >
                <Button
                  type="submit"
                  value="Send"
                  variant="contained"
                  endIcon={<SendIcon />}
                  size="large"
                >
                  {t("submit")}
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </ThemeProvider>
  );
}

ClientForm.defaultProps = {
  reservationInfo: PropTypes.array,
  setWasClientDetailsFiled: PropTypes.bool,
  setClientDetails: PropTypes.any,
  pickUpDesk: PropTypes.any,
  dropOffDesk:PropTypes.any,
  setYoungDriverClient: checkPropTypes.any,
  setVehicleExtras: PropTypes.any,
  vehicleExtras: PropTypes.any,
  youngDriverClient: PropTypes.any,
};

export default ClientForm;
