import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  FormLabel,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
const ExtrasIncrementer = ({
  extras,
  setExtras,
  totalDays,
  insuranceValue,
}) => {
  const { t } = useTranslation();

  const handleIncrement = (index) => {
    const updatedExtras = [...extras];

    if (updatedExtras[index].id === 5) {
      updatedExtras[index].value = insuranceValue;
    }

    if (updatedExtras[index].totalByDay) {
      if (updatedExtras[index].quantity < updatedExtras[index].max) {
        updatedExtras[index].quantity += 1;
        updatedExtras[index].totalValue =
          updatedExtras[index].quantity *
            updatedExtras[index].value *
            totalDays >
          updatedExtras[index].maxValue
            ? updatedExtras[index].maxValue
            : updatedExtras[index].quantity *
              updatedExtras[index].value *
              totalDays;
        setExtras(updatedExtras);
      }
    } else {
      if (updatedExtras[index].quantity < updatedExtras[index].max) {
        updatedExtras[index].quantity += 1;
        updatedExtras[index].totalValue =
          updatedExtras[index].quantity * updatedExtras[index].value >
          updatedExtras[index].maxValue
            ? updatedExtras[index].maxValue
            : updatedExtras[index].quantity * updatedExtras[index].value;
        setExtras(updatedExtras);
      }
    }
  };

  const handleDecrement = (index) => {
    const updatedExtras = [...extras];
    if (updatedExtras[index].quantity > updatedExtras[index].min) {
      updatedExtras[index].quantity -= 1;
      updatedExtras[index].totalValue =
        updatedExtras[index].quantity * updatedExtras[index].value;
      setExtras(updatedExtras);
    }
  };

  const extraPerDayChecker = (id) => {
    switch (id) {
      case 0:
        return t("perDay");
      case 1:
        return t("perDay");
      case 2:
        return t("perDay");
      case 5:
        return t("perDay");
      case 7:
        return "";
      default:
        return t("perRent");
    }
  };
  
  useEffect(() => {

  }, [])
  
  return (
    <div>
      {extras?.map((extra, index) => (
        <>
          {extra.id === 3 ? null : (
            <Container sx={{ marginBottom: "10px" }} key={extra.extraName}>
              <FormLabel sx={{ marginRight: "10px", color: "black" }}>
                <Typography>
                  <b>{t(extra.extraName)}</b>{" "}
                  {extra.value === 0 ? insuranceValue : extra.value}€{" "}
                  {extraPerDayChecker(extra.id)}{" "}
                </Typography>
              </FormLabel>
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => handleDecrement(index)}
                  disabled={extra.quantity <= extra.min}
                >
                  <Typography variant="button" display="block" gutterBottom>
                    -
                  </Typography>
                </Button>
                <span
                  style={{
                    width: "20px",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {extra.quantity}
                </span>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => handleIncrement(index)}
                  disabled={extra.quantity >= extra.max}
                >
                  <Typography variant="button" display="block" gutterBottom>
                    +
                  </Typography>
                </Button>
              </ButtonGroup>
            </Container>
          )}
        </>
      ))}
    </div>
  );
};

ExtrasIncrementer.propTypes = {
  extras: PropTypes.any.isRequired,
  setExtras: PropTypes.func.isRequired,
  totalDays: PropTypes.number.isRequired,
  insuranceValue: PropTypes.number.isRequired,
};

export default ExtrasIncrementer;
