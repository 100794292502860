import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Container, Stack, Typography } from "@mui/material";
import Optionals from "./Optionals";
import ChooseVehicle from "./ChooseVehicle";
import { useLocation } from "react-router-dom";
import ClientForm from "./ClientForm";
import Payment from "./Payment";
import Confirmation from "./Confirmation";
import moment from "moment";
const theme = createTheme({
  palette: {
    primary: {
      main: "#263677",
    },
    secondary: {
      main: "#EE8013",
    },
    danger: "#fa0505",
  },
});

function ReservationCheckout() {
  const { t } = useTranslation();
  const reservationData = useLocation();
  const reservationInfo = reservationData.state;
  const vehicleAvaibility = reservationInfo?.avaiabilities;
  const reservationDetails = reservationInfo?.reservationDetails;
  const [wasCarChoosed, setWasCarChoosed] = useState(false);
  const [wasOptionalsChoosed, setWasOptionalsChoosed] = useState(false);
  const [wasClientDetailsFiled, setWasClientDetailsFiled] = useState(false);
  const [vehicleChoosedDetails, setVehicleChoosedDetails] = useState(false);
  const [vehicleExtras, setVehicleExtras] = useState(null);
  const [totalRentalValue, setTotalRentalValue] = useState(0);
  const [clientDetails, setClientDetails] = useState(null);
  const [reservationFinalData, setReservationFinalData] = useState(null);
  const [paymentAproved, setPaymentAproved] = useState(false);
  const [paypalOrderId, setPaypalOrderId] = useState("");
  const [checkInData, setCheckInData] = useState("");
  const [reservationConfirmed, setReservationConfirmed] = useState(false);
  const [totalDays, setTotalDays] = useState(0);
  const [pageChangedFromOptional, setPageChangedFromOptional] = useState(false);
  const [youngDriverClient, setYoungDriverClient] = useState(false);

  useEffect(() => {
    if (paymentAproved) {
      const itemName = "itemName";
      const itemPrice = "itemPrice";
      const itemNameKey = `${itemName}[${vehicleChoosedDetails.vehicleCode}]`;
      const itemPriceKey = `${itemPrice}[${vehicleChoosedDetails.vehicleCode}]`;

      const equipment = {};

      vehicleExtras?.extraEquipments?.forEach((item) => {
        const { Equipment, Charge } = item;
        const { Description, EquipType } = Equipment;
        const { Amount, IncludedInRate } = Charge;
        const itemName = `itemName[${EquipType}]`;
        const itemPrice = `itemPrice[${EquipType}]`;
        const pricedEquips = `pricedEquips[${EquipType}]`;
        equipment[itemName] = Description;
        equipment[itemPrice] = Amount;
        equipment[pricedEquips] = IncludedInRate;
      });

      const coverages = {};
      vehicleExtras?.extraCoverages?.forEach((item) => {
        const { Coverage, Charge } = item;
        const { CoverageType } = Coverage;
        const { IncludedInRate } = Charge;
        const coverage = `coverages[${CoverageType}]`;
        coverages[coverage] = IncludedInRate;
      });



      const data = {
        ...reservationDetails,
        loc_pickup: reservationInfo.pickUpDesk,
        loc_return: reservationInfo.dropOffDesk,
        SIPP: vehicleChoosedDetails.vehicleCode,
        [itemNameKey]: vehicleChoosedDetails.vehicleModel,
        [itemPriceKey]: vehicleChoosedDetails.totalPrice,
        ...clientDetails,
      };

      setReservationFinalData(data);

      submitData(data);
    }
  }, [wasClientDetailsFiled, paymentAproved]);

  const submitData = async (data) => {
   
    try {
      const response = await fetch(
        "https://www.whynotcarrental.com/Backend/reservationSub.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        // Wait for the response to be parsed as JSON
        
        const reservationSubmited = await response.json();

        setReservationConfirmed(true);

        // Handle the response here
          const checkData = {
          idreserva:
            reservationSubmited.VehResRSCore.VehReservation.VehSegmentCore
              .ConfID.ID,
          customer_name:
            reservationSubmited.VehResRSCore.VehReservation.Customer.Primary
              .PersonName.GivenName,
          approvalCode: paypalOrderId,
          amount: totalRentalValue,
          rph: "PayPal",
        };
        setCheckInData(checkData);

        
        //check in request
        try {
          const response = await fetch(
            "https://www.whynotcarrental.com/Backend/reservationCheckIn.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: JSON.stringify(checkData),
            }
          );

          if (response.ok) {
            // Wait for the response to be parsed as JSON
           
            const reservationCheckInSubmited = await response.json();
           
          }
        } catch (error) {
        }
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    function calculateTotalDays(pickUpDate, pickUpTime, returnDate, returnTime) {
        const pickUpDateTime = moment(
        `${pickUpDate} ${pickUpTime}`,
        "YYYY-MM-DD HH:mm"
      );
      const returnDateTime = moment(
        `${returnDate} ${returnTime}`,
        "YYYY-MM-DD HH:mm"
      );

          // Calculate the difference in total minutes
    const diffInMinutes = returnDateTime.diff(pickUpDateTime, "minutes");
    
    // Calculate total days by dividing by minutes in a day, rounding up
    const updatedDays = Math.ceil(diffInMinutes / 1440);

      //const diffInHours = returnDateTime.diff(pickUpDateTime, "hours");
      //console.log(diffInHours / 24 , "diffInHours")

      // let totalDays;
      // if (diffInHours < 24) {
      //     totalDays = 1;
      // } else {
      //     totalDays = Math.ceil(diffInHours / 24);
      // }
  
      //const diffInDays = returnDateTime.diff(pickUpDateTime, "days");

          // Calculate the difference in hours
      //const diffInHours = returnDateTime.diff(pickUpDateTime, "hours");
    
    // Calculate total days, rounding up if there's any remainder
    // const updateDays = Math.ceil(diffInHours / 24);
    setTotalDays(updatedDays);

      // console.log(pickUpDateTime, "pickUpDateTime")
      // console.log(returnDateTime, "returnDateTime")
      // console.log(diffInDays, "diffInDays")

  
      // if (
      //   returnDateTime.hour() > pickUpDateTime.hour() ||
      //   (returnDateTime.hour() === pickUpDateTime.hour() &&
      //     returnDateTime.minute() > pickUpDateTime.minute())
      // ) {
      //   setTotalDays(diffInDays + 1);
      // } else {
      //   setTotalDays(diffInDays);
      // }
     }
    calculateTotalDays(
      reservationInfo.reservationDetails.date_pickup,
      reservationInfo.reservationDetails.time_pickup,
      reservationInfo.reservationDetails.date_return,
      reservationInfo.reservationDetails.time_return
    );
  }, [reservationInfo.reservationDetails.date_pickup, reservationInfo.reservationDetails.date_return, reservationInfo.reservationDetails.time_pickup, reservationInfo.reservationDetails.time_return, wasCarChoosed])
  

  const myVehiclePageHandler = () =>{
    if(wasCarChoosed){
      setPageChangedFromOptional(true);
      setWasCarChoosed(false);
    }
    setWasCarChoosed(false);
  }

  return (
    <ThemeProvider theme={theme}>
      <Stack
        spacing={2}
        alignItems={"center"}
        direction="column"
        sx={{
          backgroundColor: "primary.main",
          textAlign: "center",
        }}
      >
        <Breadcrumbs maxItems={reservationInfo.width > 500 ? 8 : 2}
          separator={<NavigateNextIcon fontSize="large" />}
          aria-label="breadcrumb"
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "white",
              textAlign: "center",
            }}
          >
            <Link
              to="/"
              underline="hover"
              color="inherit"
              state={vehicleAvaibility}
            >
              {t("rentalPeriod")}
            </Link>
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "white",
              textAlign: "center",
            }}
          >
            <Link
              onClick={() =>
                wasOptionalsChoosed ? null : myVehiclePageHandler()
              }
              underline="hover"
              color="inherit"
              aria-current="page"
              state={reservationInfo}
            >
              {t("myVehicle")}
            </Link>
          </Typography>
          {wasCarChoosed && (
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: "white",
                textAlign: "center",
              }}
            >
              <Link
                onClick={() => setWasOptionalsChoosed(false)}
                underline="hover"
                color="inherit"
                aria-current="page"
                state={reservationInfo}
              >
                {t("optionals")}
              </Link>
            </Typography>
          )}
          {wasOptionalsChoosed && (
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: "white",
                textAlign: "center",
              }}
            >
              <Link
                underline="hover"
                color="inherit"
                aria-current="page"
                state={reservationInfo}
                onClick={() => setWasClientDetailsFiled(false)}
              >
                {t("clientData")}
              </Link>
            </Typography>
          )}
          {wasClientDetailsFiled && (
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: "white",
                textAlign: "center",
              }}
            >
              <Link
                underline="hover"
                color="inherit"
                aria-current="page"
                state={reservationInfo}
              >
                {t("payment")}
              </Link>
            </Typography>
          )}
          {reservationConfirmed && (
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: "white",
                textAlign: "center",
              }}
            >
              <Link
                underline="hover"
                color="inherit"
                aria-current="page"
                state={reservationInfo}
              >
                {t("confirmation")}
              </Link>
            </Typography>
          )}
        </Breadcrumbs>
      </Stack>
      <Container>
        {reservationConfirmed ? (
          <Confirmation
            checkInData={checkInData}
            reservationDetails={reservationDetails}
            vehicleChoosedDetails={vehicleChoosedDetails}
            vehicleExtras={vehicleExtras}
            clientDetails={clientDetails}
            totalRentalValue={totalRentalValue}
            pickUpDesk={reservationInfo.pickUpDesk}
            dropOffDesk={reservationInfo.dropOffDesk}
            totalDays={totalDays}
          />
        ) : wasClientDetailsFiled ? (
          <div>
            <Payment
              vehicleChoosedDetails={vehicleChoosedDetails}
              reservationInfo={reservationInfo.reservationDetails}
              clientDetails={clientDetails}
              setPaymentAproved={setPaymentAproved}
              vehicleExtras={vehicleExtras}
              totalRentalValue={totalRentalValue}
              paymentAproved={paymentAproved}
              pickUpDesk={reservationInfo.pickUpDesk}
              dropOffDesk={reservationInfo.dropOffDesk}
              totalDays={totalDays}
              youngDriverClient={youngDriverClient}
              setPaypalOrderId={
                setPaypalOrderId
              } /*bookingNumber={bookingNumber}*/
            />
          </div>
        ) : wasOptionalsChoosed ? (
          <div>
            <ClientForm
              reservationInfo={reservationDetails}
              setWasClientDetailsFiled={setWasClientDetailsFiled}
              setClientDetails={setClientDetails}
              pickUpDesk={reservationInfo.pickUpDesk}
              dropOffDesk={reservationInfo.dropOffDesk}
              setYoungDriverClient={setYoungDriverClient}
              setVehicleExtras={setVehicleExtras}
              vehicleExtras={vehicleExtras}
              youngDriverClient={youngDriverClient}
            />
          </div>
        ) : wasCarChoosed ? (
          <div id="vehicleChoosed">
            <Optionals
              vehicleChoosedDetails={vehicleChoosedDetails}
              reservationInfo={reservationInfo.reservationDetails}
              setVehicleExtras={setVehicleExtras}
              setWasOptionalsChoosed={setWasOptionalsChoosed}
              setTotalRentalValue={setTotalRentalValue}
              pickUpDesk={reservationInfo.pickUpDesk}
              dropOffDesk={reservationInfo.dropOffDesk}
              totalDays={totalDays}
              wasCarChoosed={wasCarChoosed}
            />
          </div>
        ) : (
          <ChooseVehicle
            reservationInfo={reservationInfo.reservationDetails}
            avaiabilities={vehicleAvaibility}
            setWasCarChoosed={setWasCarChoosed}
            setVehicleChoosedDetails={setVehicleChoosedDetails}
            pickUpDesk={reservationInfo.pickUpDesk}
            dropOffDesk={reservationInfo.dropOffDesk}
            totalDays={totalDays}
            choosedCar={reservationInfo.choosedCar}
            wasCarChoosed={wasCarChoosed}
            pageChangedFromOptional={pageChangedFromOptional}
            setTotalDays={setTotalDays}
          />
        )}
      </Container>
    </ThemeProvider>
  );
}
export default ReservationCheckout;
