import React, { Children } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import opelAstra from "../Assets/img_car/detail/18.opel_astra.png";
import peugeot308 from "../Assets/img_car/detail/peugeot308.png";
import peugeot3008 from "../Assets/img_car/detail/peugeot3008.png";
import daciaJogger from "../Assets/img_car/detail/36.Dacia_Jogger.png";
import peugeot108 from "../Assets/img_car/detail/3.Peugeot_108.png";
import opelInsigniaStation from "../Assets/img_car/detail/opel_insignia_station.png";
import { vehicleCodeChanger } from "./vehicleCodeChanger";
import {
  CABRIO,
  ELETRIC,
  MEDIUM,
  MINIVAN,
  PREMIUM,
  SMALL,
  SUVS,
  VAN,
  VEHICLES,
} from "./Constants";


const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#EE8013",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

const FleetDisplay = ({ setQuoteMessage, setChoosedCar }) => {
  const { t } = useTranslation();
  const styles = {
    background: {
      display: "flex",
      gap: "1rem",
      justifyContent: "center",
      flexWrap: "wrap",
    },
  };

  function vehicleType(code) {
    if (SUVS.includes(code)) return t("suvCar");
    if (SMALL.includes(code)) return t("smallCar");
    if (MEDIUM.includes(code)) return t("mediumCar");
    if (CABRIO.includes(code)) return t("cabrioCar");
    if (ELETRIC.includes(code)) return "eletricCar";
    if (PREMIUM.includes(code)) return t("premiumCar");
    if (MINIVAN.includes(code)) return t("miniVanCar");
    if (VAN.includes(code)) return t("vanCar");
    return "Unknown category";
  }

  function checkFuelType(fuel, code) {
    if (code === "NTMV" || code === "CFMV" || code === "CDMV") {
      return t("petrol");
    }

    return fuel === "Petrol" ? t("petrol") : t("diesel");
  }

  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ mt: "10px" }}>
        <div style={styles.background}>
          {VEHICLES?.map(
            (
              {
                id,
                brand,
                fuelType,
                transmission,
                seats,
                doors,
                picture,
                code,
              },
              index
            ) => (
              <Box key={index}>
                <Card sx={{ margin: 2 }} key={index}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    align="center"
                    color="primary.main"
                    component="div"
                  >
                    {t("group")} {vehicleCodeChanger(code)}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    align="center"
                    component="div"
                  >
                    {brand} {t("orSimilar")}
                  </Typography>
                  <CardMedia
                    component="img"
                    sx={{
                      height: 220, // Fixed height for consistency
                      width: 300, // Fixed width
                      maxHeight: "100%", // Ensure the image doesn’t exceed the container's height
                      maxWidth: "100%", // Ensure the image doesn’t exceed the container's width
                      objectFit: "contain", // Fit the image within the container, may result in some empty space
                      display: "block", // Center the image horizontally
                      margin: "0 auto", // Center the image horizontally
                    }}
                    image={
                      picture
                        ? process.env.PUBLIC_URL + "img_car/detail/" + picture
                        : code === "CDAV"
                        ? opelAstra
                        : code === "CDAD"
                        ? peugeot308
                        : code === "CGAD"
                        ? peugeot3008
                        : code === "JVMR"
                        ? daciaJogger
                        : code === "MDMV"
                        ? peugeot108
                        : code === "CWMD"
                        ? opelInsigniaStation
                        : null
                    }
                    title="CAR"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {t("carDetails")}:
                    </Typography>
                    <Typography gutterBottom variant="h7" component="div">
                      <b>{t("vehicleType")}</b> {vehicleType(code)}
                    </Typography>
                    <Typography gutterBottom variant="h7" component="div">
                      <b>{t("fuelType")}</b> {checkFuelType(fuelType, code)}
                    </Typography>
                    <Typography gutterBottom variant="h7" component="div">
                      <b>{t("gearbox")}</b>{" "}
                      {[
                        "EDAV",
                        "MDAV",
                        "CTAV",
                        "RVAD",
                        "HDAE",
                        "CDAV",
                        "CDAD",
                        "CGAV",
                        "CGAD",
                        "DDAD",
                        "PDAD",
                        "SFAD",
                      ].includes(code)
                        ? t("automatic")
                        : t("manual")}
                    </Typography>
                    <Typography gutterBottom variant="h7" component="div">
                      <b>{t("seats")}</b> {seats}
                    </Typography>
                    <Typography gutterBottom variant="h7" component="div">
                      <b>{t("doors")}</b> {doors}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Box component="div">
                      <Button
                        sx={{ bottom: "10px" }}
                        variant="contained"
                        size="large"
                      >
                        <Link
                          smooth
                          to="#body"
                          onClick={() => setChoosedCar(code)}
                        >
                          {t("reserve")}
                        </Link>
                      </Button>
                    </Box>
                  </CardActions>
                  <CardActions>
                    <Box componentAct="div">
                      <Button
                        sx={{ bottom: "10px" }}
                        variant="contained"
                        size="large"
                      >
                        <Link
                          smooth
                          to="#contacts"
                          onClick={() =>
                            setQuoteMessage(
                              t("quoteMessage") + vehicleCodeChanger(code)
                            )
                          }
                        >
                          {t("requestQuote")}
                        </Link>
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Box>
            )
          )}
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default FleetDisplay;
