import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Typography,
  Stack,
  Grid,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
} from "@mui/material";
import opelAstra from "../Assets/img_car/detail/18.opel_astra.png";
import peugeot308 from "../Assets/img_car/detail/peugeot308.png";
import peugeot3008 from "../Assets/img_car/detail/peugeot3008.png";
import daciaJogger from "../Assets/img_car/detail/36.Dacia_Jogger.png";
import peugeot108 from "../Assets/img_car/detail/3.Peugeot_108.png";
import opelInsigniaStation from "../Assets/img_car/detail/opel_insignia_station.png";
import { vehicleCodeChanger } from "../helpers/vehicleCodeChanger";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CABRIO,
  DESKS,
  ELETRIC,
  MEDIUM,
  MINIVAN,
  PREMIUM,
  SMALL,
  SUVS,
  VAN,
} from "../helpers/Constants";
import InformationModal from "../Components/Modal/InformationModal";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import ErrorModal from "../Components/Modal/ErrorModal";
import { useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router";
import moment from "moment";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#EE8013",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

const styles = {
  background: {
    display: "flex",
    gap: "3rem",
    justifyContent: "center",
    flexWrap: "wrap",
  },
};

function ChooseVehicle({
  reservationInfo = { undefined },
  avaiabilities = { undefined },
  setWasCarChoosed = { undefined },
  setVehicleChoosedDetails = { undefined },
  pickUpDesk = { undefined },
  dropOffDesk = { undefined },
  totalDays = { undefined },
  choosedCar = { undefined },
  wasCarChoosed = { undefined },
  pageChangedFromOptional = { undefined },
  setTotalDays = { undefined },
}) {
  const { t } = useTranslation();
  const handleReservationOptionals = (
    vehicleModel,
    vehicleCode,
    fuelType,
    pictureUrl,
    transmissionType,
    doorCount,
    passengerQuantity,
    totalPrice,
    SCDW,
    GTW,
    extraFees,
    extraEquipments,
    coverages,
    updatedTotalDays
  ) => {
    setWasCarChoosed(true);
    setVehicleChoosedDetails({
      vehicleModel,
      vehicleCode,
      fuelType,
      pictureUrl,
      transmissionType,
      doorCount,
      passengerQuantity,
      totalPrice,
      SCDW,
      GTW,
      extraFees,
      extraEquipments,
      coverages,
      updatedTotalDays
    });
  };

  const [currentAvaiabilities, setCurrentAvaiabilities] =
    useState(avaiabilities);

  const navigate = useNavigate();

  const [cars, setCars] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false); // State to control modal visibility
  const [openInformationModal, setOpenInformationModal] = useState(false); // State to control modal visibility
  const [openAvaiabilityInformationModal, setOpenAvaiabilityInformationModal] =
    useState(false); // State to control modal visibility
  const [wasModalShowed, setWasModalShowed] = useState(false);
  const [updatedTotalDays, setUpdatedTotalDays] = useState(totalDays);

  useEffect(() => {
    const vehiclesAvaiable =
      currentAvaiabilities?.flatMap((innerArr) => innerArr?.VehAvail) ?? [];

    const codeCounts = {};

    for (const obj of vehiclesAvaiable) {
      const code = obj?.VehAvailCore?.Vehicle?.VehMakeModel?.Code; // assuming the code property is named "code"
      if (codeCounts[code]) {
        codeCounts[code]++;
      } else {
        codeCounts[code] = 1;
      }
    }

    setCars(
      [
        ...new Set(
          vehiclesAvaiable.map(
            (obj) => obj?.VehAvailCore?.Vehicle?.VehMakeModel?.Code
          )
        ),
      ].map((code) =>
        vehiclesAvaiable.find(
          (obj) => obj?.VehAvailCore?.Vehicle?.VehMakeModel?.Code === code
        )
      )
    );
  }, [currentAvaiabilities]);

  const vehicles = cars?.map((vehicle) => ({
    vehicleModel: vehicle?.VehAvailCore?.Vehicle?.VehMakeModel?.Name,
    vehicleCode: vehicle?.VehAvailCore?.Vehicle?.VehMakeModel?.Code,
    fuelType: vehicle?.VehAvailCore?.Vehicle?.FuelType,
    pictureUrl: vehicle?.VehAvailCore?.Vehicle?.PictureURL,
    transmissionType: vehicle?.VehAvailCore?.Vehicle?.TransmissionType,
    doorCount: vehicle?.VehAvailCore?.Vehicle?.VehType?.DoorCount,
    passengerQuantity: vehicle?.VehAvailCore?.Vehicle?.PassengerQuantity,
    totalPrice: vehicle?.VehAvailCore?.TotalCharge?.RateTotalAmount,
    SCDW: vehicle?.VehAvailInfo?.PricedCoverages?.PricedCoverage[1]?.Charge
      ?.Amount,
    GTW: vehicle?.VehAvailInfo?.PricedCoverages?.PricedCoverage[2]?.Charge
      ?.Amount,
    extraFees: vehicle?.VehAvailCore?.Fees?.Fee,
    extraEquipments: vehicle?.VehAvailCore?.PricedEquips?.PricedEquip,
    coverages: vehicle?.VehAvailInfo?.PricedCoverages?.PricedCoverage,
  }));

  //(dayjs(pickUpDate?.$d).format("DD/MM/YYYY")

  function vehicleType(code) {
    if (SUVS.includes(code)) return t("suvCar");
    if (SMALL.includes(code)) return t("smallCar");
    if (MEDIUM.includes(code)) return t("mediumCar");
    if (CABRIO.includes(code)) return t("cabrioCar");
    if (ELETRIC.includes(code)) return "eletricCar";
    if (PREMIUM.includes(code)) return t("premiumCar");
    if (MINIVAN.includes(code)) return t("miniVanCar");
    if (VAN.includes(code)) return t("vanCar");
    return "Unknown category";
  }

  function checkFuelType(fuel, code) {
    if (code === "NTMV" || code === "CFMV" || code === "CDMV") {
      return t("petrol");
    }

    return fuel === "Petrol" ? t("petrol") : t("diesel");
  }

  useEffect(() => {
    if (!pageChangedFromOptional) {
      if (choosedCar.code) {
        const filteredVehicles = vehicles?.filter(
          ({ vehicleCode }) => vehicleCode === choosedCar.code
        ); // Store filtered vehicles
        if (filteredVehicles?.length === 0 && !wasModalShowed) {
          setOpenAvaiabilityInformationModal(true);
        } else {
          filteredVehicles?.map(
            ({
              vehicleModel,
              vehicleCode,
              fuelType,
              pictureUrl,
              transmissionType,
              doorCount,
              passengerQuantity,
              totalPrice,
              SCDW,
              GTW,
              extraFees,
              extraEquipments,
              coverages,
            }) =>
              handleReservationOptionals(
                vehicleModel,
                vehicleCode,
                fuelType,
                pictureUrl,
                transmissionType,
                doorCount,
                passengerQuantity,
                totalPrice,
                SCDW,
                GTW,
                extraFees,
                extraEquipments,
                coverages
              )
          );
        }
      }
    }
  }, [vehicles, choosedCar]);

  const handleCloseInformationModal = () => {
    setWasModalShowed(true);
    setOpenInformationModal(false);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleCloseAvaiabilityInformationModal = () => {
    setOpenAvaiabilityInformationModal(false);
  };

  const validateDateReturn = (value, { parent }) => {
    if (!value || !parent.date_pickup) {
      return true;
    }
    return value >= parent.date_pickup;
  };

  const reservationTimeValidation = yup.object({
    loc_pickup: yup.number().required(t("mandatory")),
    date_pickup: yup
      .date()
      .required(t("mandatory"))
      .min(dayjs().add(2, "day"), t("pickUpDateValidation"))
      .typeError(t("validDate")),
    time_pickup: yup
      .string()
      .required(t("mandatory"))
      .test("time", t("outsideBusinessHours"), (value) => {
        const hours = parseInt(value.split(":")[0]);
        const minutes = parseInt(value.split(":")[1]);

        return hours * 60 + minutes >= 510 && hours * 60 + minutes <= 1110;
      }),
    loc_return: yup.number().required(t("mandatory")),
    date_return: yup
      .date()
      .required(t("mandatory"))
      .typeError(t("validDate"))
      .test("dateReturn", t("returnDateValidation"), validateDateReturn),
    time_return: yup
      .string()
      .required(t("mandatory"))
      .test("time", t("outsideBusinessHours"), (value) => {
        const hours = parseInt(value.split(":")[0]);
        const minutes = parseInt(value.split(":")[1]);

        return hours * 60 + minutes >= 510 && hours * 60 + minutes <= 1110;
      }),
  });

  const [width, setWidth] = React.useState(window.innerWidth);
  const [isLoading, setIsloading] = useState(false);
  const [newPickUpDesk, setNewPickUpDesk] = useState(pickUpDesk);
  const [newDropOffDesk, setNewDropOffDesk] = useState(dropOffDesk);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChangePickUpDesk = (event) => {
    setValue("loc_pickup", event.target.value, { shouldValidate: true });
    setValue("loc_return", event.target.value, { shouldValidate: true });
    setNewPickUpDesk(event.target.value ?? getValues("loc_pickup"));
    setNewDropOffDesk(event.target.value ?? getValues("loc_return"));

    if (event.target.value === 47 || event.target.value === 46) {
      setOpenInformationModal(true);
    }
  };

  const handleChangeDropOffDesk = (event) => {
    setValue("loc_return", event.target.value, { shouldValidate: true });
    setNewDropOffDesk(event.target.value || getValues("loc_return"));
    if (event.target.value === 47 || event.target.value === 46) {
      setOpenInformationModal(true);
    }
  };

  const handleChangeTimePickup = (time) => {
    if (time?.$m === 0) {
      setValue("time_pickup", `${time?.$H}:${time?.$m}0`, {
        shouldValidate: true,
      });
    } else {
      setValue("time_pickup", `${time?.$H}:${time?.$m}`, {
        shouldValidate: true,
      });
    }
  };

  const handleChangeTimeReturn = (time) => {
    if (time?.$m === 0) {
      setValue("time_return", dayjs(`${time?.$H}:${time?.$m}0`), {
        shouldValidate: true,
      });
    } else {
      setValue("time_return", dayjs(`${time?.$H}:${time?.$m}`), {
        shouldValidate: true,
      });
    }
  };

  const handleChangeDatePickup = (date) => {
    setValue("date_pickup", dayjs(date?.$d).format("YYYY-MM-DD"), {
      shouldValidate: true,
    });
  };

  const handleChangeDateReturn = (date) => {
    setValue("date_return", dayjs(date?.$d).format("YYYY-MM-DD"), {
      shouldValidate: true,
    });
  };

  const [error, setError] = React.useState(null);
  const [getAvaiabilities, setAvaiabilities] = useState("");
  const locations = [
    { id: 41, name: "Funchal" },
    { id: 42, name: "Airport" },
    { id: 47, name: "Calheta" },
    { id: 46, name: "Norte" },
  ];

  const {
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      loc_pickup: newPickUpDesk ?? pickUpDesk,
      date_pickup: reservationInfo?.date_pickup,
      time_pickup: reservationInfo?.time_pickup,
      loc_return: newDropOffDesk ?? dropOffDesk,
      date_return: reservationInfo?.date_return,
      time_return: reservationInfo?.time_return,
    },
    resolver: yupResolver(reservationTimeValidation),
    reValidateMode: "onChange",
  });

  const calculateTotalDays = (
    pickUpDate,
    pickUpTime,
    returnDate,
    returnTime
  ) => {
    const pickUpDateTime = moment(
      `${pickUpDate} ${pickUpTime}`,
      "YYYY-MM-DD HH:mm"
    );
    const returnDateTime = moment(
      `${returnDate} ${returnTime}`,
      "YYYY-MM-DD HH:mm"
    );

    const diffInDays = returnDateTime.diff(pickUpDateTime, "days");

    if (
      returnDateTime.hour() > pickUpDateTime.hour() ||
      (returnDateTime.hour() === pickUpDateTime.hour() &&
        returnDateTime.minute() > pickUpDateTime.minute())
    ) {
      setTotalDays(diffInDays + 1);
      setUpdatedTotalDays(diffInDays + 1);
    } else {
      setTotalDays(diffInDays);
      setUpdatedTotalDays(diffInDays);
    }
  };

  const calculateTotalPrice = (price, days) => {
    const total = (Math.round(price * 100) / 100 / days).toFixed(2);
    return total;
  };

  const fetchAvailabilities = async () => {
    const newAvaiabilities = [];

    for (const location of locations) {
      setValue("loc_pickup", location.id);
      setValue("loc_return", location.id);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify(getValues()),
      };

      const response = await fetch(
        "https://www.whynotcarrental.com/Backend/vehicleAvaibility.php",
        requestOptions
      );

      const vehicleAvaibility = await response.json();

      newAvaiabilities.push(
        vehicleAvaibility.VehAvailRSCore.VehVendorAvails.VehVendorAvail
          .VehAvails
      );
    }

    const mergedAvaiabilities = [
      ...new Set([...getAvaiabilities, ...newAvaiabilities]),
    ];

    setAvaiabilities(mergedAvaiabilities);
    return mergedAvaiabilities; // Return the mergedAvaiabilities
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    calculateTotalDays(
      getValues("date_pickup"),
      getValues("time_pickup"),
      getValues("date_return"),
      getValues("time_return")
    );

    await trigger();
    if (Object.keys(errors).length === 0 && isValid) {
      try {
        setIsloading(true);
        const avaiabilities = await fetchAvailabilities(); // Wait for fetchAvailabilities to complete

        setIsloading(false);
        // navigate("/reservationCheckout", {
        //   state: {
        //     avaiabilities,
        //     reservationDetails: getValues(),
        //     pickUpDesk,
        //     dropOffDesk,
        //     width,
        //     choosedCar,
        //   },
        // });

        setCurrentAvaiabilities(avaiabilities);
        setValue("loc_pickup", newPickUpDesk);
        setValue("loc_return", newDropOffDesk);
      } catch (error) {
        setIsloading(false);
        setError("Network error. Please try again later.");
      }
    } else {
      setOpenErrorModal(true);
      Object.entries(errors).forEach(([field, error]) => {});
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        {/* <div>
          <InformationModal
            open={openAvaiabilityInformationModal}
            handleClose={handleCloseAvaiabilityInformationModal}
            message={t("requestCarUnavaiable")}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form onSubmit={(event) => handleSubmit(event)}>
              <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                errors={errors}
              />
              <InformationModal
                open={openInformationModal}
                handleClose={handleCloseInformationModal}
                message={t("hotelDeliveryInfo")}
              />
              <Accordion
                sx={{
                  marginTop: 2,
                  backgroundColor: theme.palette.secondary.main,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="reservationDetails"
                >
                  <Typography color={"white"} variant="h5">
                    {" "}
                    Detalhes da reserva
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      paddingTop: 3,
                      paddingBottom: 3,
                      paddingLeft: 5,
                      paddingRight: 5,
                      borderRadius: 4,
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#f9fafc",
                      width: "100%",
                    }}
                  >
                    {width > 1200 ? (
                      <>
                        <Grid
                          container
                          justifyContent="space-around"
                          sx={{
                            marginBottom: 1,
                            marginTop: 1,
                            flexDirection: { xs: "column", md: "row" },
                          }}
                        >
                          <Stack alignItems="center" direction="row" gap={1}>
                            <DirectionsCarIcon
                              sx={{
                                color: theme.palette.secondary.main,
                                fontSize: 30,
                              }}
                            />
                            <Typography
                              color={theme.palette.primary.main}
                              variant="h5"
                            >
                              {t("pickUp")}
                            </Typography>
                          </Stack>

                          <Stack alignItems="center" direction="row" gap={1}>
                            <LocationOnIcon
                              sx={{
                                color: theme.palette.secondary.main,
                                fontSize: 30,
                              }}
                            />
                            <Typography
                              color={theme.palette.primary.main}
                              variant="h5"
                            >
                              {t("dropOff")}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            flexWrap: {
                              xs: "wrap",
                              sm: "wrap",
                              lg: "nowrap",
                            }, // Wrap on small screens, no wrap on larger
                          }}
                        >
                          <Grid item sx={{ width: { lg: "100%", sm: "auto" } }}>
                            {" "}
                            <FormControl
                              sx={{ minWidth: { lg: "100%", xl: 230 } }}
                            >
                              {" "}
                              <Typography
                                color={theme.palette.primary.main}
                                variant="subtitle1"
                                gutterBottom
                                id="loc_pickup"
                              >
                                {t("location")}
                              </Typography>
                              <Select
                                labelid="loc_pickup"
                                id="loc_pickup"
                                value={getValues("loc_pickup")}
                                name="loc_pickup"
                                label={t("deliveryDesk")}
                                onError={(newError) => setError(newError)}
                                onChange={handleChangePickUpDesk}
                              >
                                {DESKS.map(({ code, name }, index) => (
                                  <MenuItem
                                    label={name}
                                    key={index}
                                    value={code}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item sx={{ width: { lg: "100%" } }}>
                            <Typography
                              color={theme.palette.primary.main}
                              variant="subtitle1"
                              gutterBottom
                            >
                              {t("date")}
                            </Typography>
                            <FormControl
                              sx={{
                                minWidth: { lg: "100%", xl: 150 },
                                width: { lg: 130 },
                              }}
                            >
                              <DatePicker
                                disablePast
                                name="date_pickup"
                                format="DD-MM-YYYY"
                                minDate={dayjs().add(3, "day")}
                                value={dayjs(getValues("date_pickup"))}
                                onChange={handleChangeDatePickup}
                                error={!!errors.date_pickup}
                                onError={(newError) => setError(newError)}
                              />
                            </FormControl>
                          </Grid>

                          <Grid
                            item
                            sx={{
                              width: { lg: "100%" },
                              marginRight: { sm: 5 },
                            }}
                          >
                            <Typography
                              color={theme.palette.primary.main}
                              variant="subtitle1"
                              gutterBottom
                            >
                              {t("time")}
                            </Typography>
                            <FormControl
                              sx={{ minWidth: { lg: "100%", xl: 100 } }}
                            >
                              <TimePicker
                                value={dayjs(getValues("time_pickup"), "HH:mm")}
                                onChange={handleChangeTimePickup}
                                ampm={false}
                                error={!!errors.time_pickup}
                                onError={(newError) => setError(newError)}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item sx={{ width: { lg: "100%" } }}>
                            <Typography
                              color={theme.palette.primary.main}
                              variant="subtitle1"
                              gutterBottom
                              id="loc_return"
                            >
                              {t("location")}
                            </Typography>
                            <FormControl
                              sx={{ minWidth: { lg: "100%", xl: 250 } }}
                            >
                              <Select
                                required
                                labelid="loc_return"
                                id="loc_return"
                                value={getValues("loc_return")}
                                name="loc_return"
                                label={t("returnDesk")}
                                onChange={handleChangeDropOffDesk}
                              >
                                {DESKS.map(({ code, name }, index) => (
                                  <MenuItem
                                    aria-label={name}
                                    key={index}
                                    value={code}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item sx={{ width: { lg: "100%" } }}>
                            <Typography
                              color={theme.palette.primary.main}
                              variant="subtitle1"
                              gutterBottom
                            >
                              {t("date")}
                            </Typography>
                            <FormControl
                              sx={{
                                minWidth: { lg: "100%", xl: 150 },
                                width: { lg: 130 },
                              }}
                            >
                              <DatePicker
                                disablePast
                                name="date_return"
                                format="DD-MM-YYYY"
                                onError={(newError) => setError(newError)}
                                minDate={dayjs(getValues("date_pickup"))}
                                value={dayjs(getValues("date_return"))}
                                onChange={handleChangeDateReturn}
                                error={!!errors.date_return}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item sx={{ width: "100%" }}>
                            <Typography
                              color={theme.palette.primary.main}
                              variant="subtitle1"
                              gutterBottom
                            >
                              {t("time")}
                            </Typography>
                            <FormControl sx={{ minWidth: "100%" }}>
                              <TimePicker
                                value={dayjs(getValues("time_pickup"), "HH:mm")}
                                onChange={handleChangeTimeReturn}
                                ampm={false}
                                onError={(newError) => setError(newError)}
                                error={!!errors.time_return}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid container direction="column" spacing={2}>
                          <Stack
                            alignItems="center"
                            direction="row"
                            gap={1}
                            sx={{ marginBottom: 1 }}
                          >
                            <DirectionsCarIcon
                              sx={{
                                color: theme.palette.secondary.main,
                                fontSize: 30,
                              }}
                            />
                            <Typography
                              color={theme.palette.primary.main}
                              variant="h5"
                              sx={{
                                "@media (max-width: 610px)": {
                                  fontSize: "1.2rem", // Reduce font size on smaller screens
                                },
                              }}
                            >
                              {t("pickUp")}
                            </Typography>
                          </Stack>

                          <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              flexWrap: "nowrap", // Default behavior
                              "@media (max-width: 610px)": {
                                flexWrap: "wrap", // Stack items vertically on small screens
                              },
                            }}
                          >
                            <Grid item>
                              <FormControl
                                sx={{
                                  minWidth: 250,
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  gutterBottom
                                  labelid="loc_pickup"
                                >
                                  {t("location")}
                                </Typography>
                                <Select
                                  labelid="loc_pickup"
                                  id="loc_pickup"
                                  value={getValues("loc_pickup")}
                                  name="loc_pickup"
                                  label={t("deliveryDesk")}
                                  onError={(newError) => setError(newError)}
                                  onChange={handleChangePickUpDesk}
                                >
                                  {DESKS.map(({ code, name }, index) => (
                                    <MenuItem
                                      aria-label={name}
                                      key={index}
                                      value={code}
                                    >
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item>
                              <Typography variant="subtitle1" gutterBottom>
                                {t("date")}
                              </Typography>
                              <FormControl
                                size="medium"
                                sx={{
                                  "@media (max-width: 610px)": {
                                    minWidth: 250,
                                  },
                                }}
                              >
                                <DatePicker
                                  disablePast
                                  name="date_pickup"
                                  format="DD-MM-YYYY"
                                  minDate={dayjs().add(3, "day")}
                                  value={dayjs(getValues("date_pickup"))}
                                  onChange={handleChangeDatePickup}
                                  error={!!errors.date_pickup}
                                  onError={(newError) => setError(newError)}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item>
                              <Typography variant="subtitle1" gutterBottom>
                                {t("time")}
                              </Typography>
                              <FormControl
                                size="medium"
                                sx={{
                                  "@media (max-width: 610px)": {
                                    minWidth: 250,
                                  },
                                }}
                              >
                                <TimePicker
                                  value={dayjs(
                                    getValues("time_return"),
                                    "HH:mm"
                                  )}
                                  onChange={handleChangeTimePickup}
                                  ampm={false}
                                  error={!!errors.time_pickup}
                                  onError={(newError) => setError(newError)}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          direction="column"
                          spacing={2}
                          sx={{ marginTop: 4 }}
                        >
                          <Stack
                            alignItems="center"
                            direction="row"
                            gap={1}
                            sx={{ marginBottom: 1 }}
                          >
                            <LocationOnIcon
                              sx={{
                                color: theme.palette.secondary.main,
                                fontSize: 30,
                              }}
                            />
                            <Typography
                              color={theme.palette.primary.main}
                              variant="h5"
                              sx={{
                                "@media (max-width: 610px)": {
                                  fontSize: "1.2rem", // Reduce font size on small screens
                                },
                              }}
                            >
                              {t("dropOff")}
                            </Typography>
                          </Stack>
                          <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              flexWrap: "nowrap", // Default behavior
                              "@media (max-width: 610px)": {
                                flexWrap: "wrap", // Stack items vertically on small screens
                              },
                            }}
                          >
                            <Grid item>
                              <FormControl
                                sx={{
                                  minWidth: 250,
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  gutterBottom
                                  id="loc_return"
                                >
                                  {t("location")}
                                </Typography>
                                <Select
                                  labelid="loc_return"
                                  id="loc_return"
                                  value={getValues("loc_return")}
                                  name="loc_return"
                                  label={t("returnDesk")}
                                  onChange={handleChangeDropOffDesk}
                                >
                                  {DESKS.map(({ code, name }, index) => (
                                    <MenuItem
                                      aria-label={name}
                                      key={index}
                                      value={code}
                                    >
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item>
                              <Typography variant="subtitle1" gutterBottom>
                                {t("date")}
                              </Typography>
                              <FormControl
                                size="medium"
                                sx={{
                                  "@media (max-width: 610px)": {
                                    minWidth: 250,
                                  },
                                }}
                              >
                                <DatePicker
                                  disablePast
                                  name="date_return"
                                  format="DD-MM-YYYY"
                                  onError={(newError) => setError(newError)}
                                  minDate={dayjs(getValues("date_pickup"))}
                                  value={dayjs(getValues("date_return"))}
                                  onChange={handleChangeDateReturn}
                                  error={!!errors.date_return}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item>
                              <Typography variant="subtitle1" gutterBottom>
                                {t("time")}
                              </Typography>
                              <FormControl
                                size="medium"
                                sx={{
                                  "@media (max-width: 610px)": {
                                    minWidth: 250,
                                  },
                                }}
                              >
                                <TimePicker
                                  value={dayjs(
                                    getValues("time_return"),
                                    "HH:mm"
                                  )}
                                  onChange={handleChangeTimeReturn}
                                  ampm={false}
                                  onError={(newError) => setError(newError)}
                                  error={!!errors.time_return}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid
                      item
                      sx={{
                        textAlign: { lg: "center", xl: "right" },
                        width: "100%",
                      }}
                    >
                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          type="submit"
                          value="Send"
                          variant="contained"
                          endIcon={<SendIcon />}
                          size="large"
                          disabled={isLoading}
                          sx={{
                            marginTop: 2,
                          }}
                        >
                          <input
                            type="hidden"
                            name="action"
                            value="GetVehAvailRate"
                          ></input>
                          {t("search")}
                        </Button>
                      )}
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </form>
          </LocalizationProvider> 
        </div>*/}
        <Container sx={{ mt: "10px" }}>
          <Typography
            variant="h4"
            component="div"
            sx={{
              flexGrow: 1,
              color: "primary.main",
              textAlign: "center",
              mt: "1rem",
            }}
          >
            {t("fleet")}
          </Typography>
          <div style={styles.background}>
            {vehicles ? (
              vehicles
                ?.sort((a, b) => a.totalPrice - b.totalPrice)
                ?.map(
                  (
                    {
                      vehicleModel,
                      vehicleCode,
                      fuelType,
                      pictureUrl,
                      transmissionType,
                      doorCount,
                      passengerQuantity,
                      totalPrice,
                      SCDW,
                      GTW,
                      extraFees,
                      extraEquipments,
                      coverages,
                    },
                    index
                  ) => (
                    <Box key={index}>
                      {isLoading ? (
                        <Skeleton
                          variant="rectangular"
                          width={300}
                          height={615}
                        />
                      ) : vehicleCode ? (
                        <Card sx={{ maxWidth: 800 }} key={index}>
                          <Typography
                            gutterBottom
                            variant="h5"
                            align="center"
                            color="primary.main"
                            component="div"
                          >
                            {t("group")} {vehicleCodeChanger(vehicleCode)}
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="h6"
                            align="center"
                            component="div"
                          >
                            {vehicleModel === "CWMD"
                              ? "Opel Insignia Station"
                              : vehicleModel}{" "}
                            {t("orSimilar")}
                          </Typography>
                          <CardMedia
                            component="img"
                            sx={{
                              height: 220, // Fixed height for consistency
                              width: 300, // Fixed width
                              maxHeight: "100%", // Ensure the image doesn’t exceed the container's height
                              maxWidth: "100%", // Ensure the image doesn’t exceed the container's width
                              objectFit: "contain", // Fit the image within the container, may result in some empty space
                              display: "block", // Center the image horizontally
                              margin: "0 auto", // Center the image horizontally
                            }}
                            image={
                              pictureUrl
                                ? process.env.PUBLIC_URL +
                                  "img_car/detail/" +
                                  pictureUrl
                                : vehicleCode === "CDAV"
                                ? opelAstra
                                : vehicleCode === "CDAD"
                                ? peugeot308
                                : vehicleCode === "CGAD"
                                ? peugeot3008
                                : vehicleCode === "JVMR"
                                ? daciaJogger
                                : vehicleCode === "MDMV"
                                ? peugeot108
                                : vehicleCode === "CWMD"
                                ? opelInsigniaStation
                                : null
                            }
                            title="CAR"
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                            >
                              {t("carDetails")}:
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="h7"
                              component="div"
                            >
                              <b>{t("vehicleType")}</b>{" "}
                              {vehicleType(vehicleCode)}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="h7"
                              component="div"
                            >
                              <b>{t("fuelType")}</b>{" "}
                              {checkFuelType(fuelType, vehicleCode)}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="h7"
                              component="div"
                            >
                              <b>{t("gearbox")}</b>{" "}
                              {[
                                "EDAV",
                                "MDAV",
                                "CTAV",
                                "RVAD",
                                "HDAE",
                                "CDAV",
                                "CDAD",
                                "CGAV",
                                "CGAD",
                                "DDAD",
                                "PDAD",
                                "SFAD",
                              ].includes(vehicleCode)
                                ? t("automatic")
                                : t("manual")}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="h7"
                              component="div"
                            >
                              <b>{t("seats")}</b> {passengerQuantity}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="h7"
                              component="div"
                            >
                              <b>{t("doors")}</b> {doorCount}
                            </Typography>
                            <Typography
                              gutterBottom
                              align="center"
                              color="primary.main"
                              variant="h6"
                              component="div"
                            >
                              <b>{t("from")} </b>{" "}
                              {calculateTotalPrice(
                                totalPrice,
                                updatedTotalDays === 0
                                  ? totalDays
                                  : updatedTotalDays
                              )}
                              €/{t("day")}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              sx={{ bottom: "10px" }}
                              variant="contained"
                              size="large"
                              onClick={() =>
                                handleReservationOptionals(
                                  vehicleModel,
                                  vehicleCode,
                                  fuelType,
                                  pictureUrl,
                                  transmissionType,
                                  doorCount,
                                  passengerQuantity,
                                  totalPrice,
                                  SCDW,
                                  GTW,
                                  extraFees,
                                  extraEquipments,
                                  coverages,
                                  updatedTotalDays
                                )
                              }
                            >
                              {t("reserve")}
                            </Button>
                          </CardActions>
                        </Card>
                      ) : null}
                    </Box>
                  )
                )
            ) : (
              <p>{t("noCarsAvaiable")}</p>
            )}
          </div>
        </Container>
      </>
    </ThemeProvider>
  );
}

ChooseVehicle.defaultProps = {
  reservationInfo: PropTypes.any,
  avaiabilities: PropTypes.array,
  pickUpDesk: PropTypes.number,
  dropOffDesk: PropTypes.number,
  totalDays: PropTypes.number,
  choosedCar: PropTypes.any,
  wasCarChoosed: PropTypes.any,
  pageChangedFromOptional: PropTypes.any,
  setTotalDays: PropTypes.any,
};

export default ChooseVehicle;
