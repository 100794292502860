import React from "react";
import { Modal, Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

// InformationModel Component to display the information
const InformationModal = ({ open, handleClose, message }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        {/* Display the information */}
          <ul>
          <Typography  variant="h5">{t("information")}: </Typography> <Typography>{message}</Typography>
          </ul>
        
        {/* Close Button */}
        <Button onClick={handleClose} sx={{ mt: 2 }}>
          {t("close")}
        </Button>
      </Box>
    </Modal>
  );
};

export default InformationModal;
