import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { VEHICLES } from "../../helpers/Constants";
import {
  CABRIO,
  ELETRIC,
  MEDIUM,
  MINIVAN,
  PREMIUM,
  SMALL,
  SUVS,
  VAN,
} from "../../helpers/Constants";
import opelAstra from "../../Assets/img_car/detail/18.opel_astra.png";
import peugeot308 from "../../Assets/img_car/detail/peugeot308.png";
import peugeot3008 from "../../Assets/img_car/detail/peugeot3008.png";
import daciaJogger from "../../Assets/img_car/detail/36.Dacia_Jogger.png";
import peugeot108 from "../../Assets/img_car/detail/3.Peugeot_108.png";
import opelInsigniaStation from "../../Assets/img_car/detail/opel_insignia_station.png";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { vehicleCodeChanger } from "../../helpers/vehicleCodeChanger";
import { HashLink as Link } from "react-router-hash-link";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function CarouselComponent({ setQuoteMessage, setChoosedCar }) {
  const { t } = useTranslation();
  function vehicleType(code) {
    if (SUVS.includes(code)) return t("suvCar");
    if (SMALL.includes(code)) return t("smallCar");
    if (MEDIUM.includes(code)) return t("mediumCar");
    if (CABRIO.includes(code)) return t("cabrioCar");
    if (ELETRIC.includes(code)) return "eletricCar";
    if (PREMIUM.includes(code)) return t("premiumCar");
    if (MINIVAN.includes(code)) return t("miniVanCar");
    if (VAN.includes(code)) return t("vanCar");
    return "Unknown category";
  }

  function checkFuelType(fuel, code) {
    if (code === "NTMV" || code === "CFMV" || code === "CDMV") {
      return t("petrol");
    }

    return fuel === "Petrol" ? t("petrol") : t("diesel");
  }

  return (
    <>
      <div className="Carousel">
        <Carousel swipeable draggable infinite responsive={responsive}>
          {VEHICLES?.map(
            (
              {
                id,
                brand,
                fuelType,
                transmission,
                seats,
                doors,
                picture,
                code,
              },
              index
            ) => (
              <Box key={index}>
                <Card sx={{ margin: 5 }} key={index}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    align="center"
                    color="primary.main"
                    component="div"
                  >
                    {t("group")} {vehicleCodeChanger(code)}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    align="center"
                    component="div"
                  >
                    {brand} {t("orSimilar")}
                  </Typography>
                  <CardMedia
                    component="img"
                    sx={{
                      height: 220, // Fixed height for consistency
                      width: 300, // Fixed width
                      maxHeight: "100%", // Ensure the image doesn’t exceed the container's height
                      maxWidth: "100%", // Ensure the image doesn’t exceed the container's width
                      objectFit: "contain", // Fit the image within the container, may result in some empty space
                      display: "block", // Center the image horizontally
                      margin: "0 auto", // Center the image horizontally
                    }}
                    image={
                      picture
                        ? process.env.PUBLIC_URL + "img_car/detail/" + picture
                        : code === "CDAV"
                        ? opelAstra
                        : code === "CDAD"
                        ? peugeot308
                        : code === "CGAD"
                        ? peugeot3008
                        : code === "JVMR"
                        ? daciaJogger
                        : code === "MDMV"
                        ? peugeot108
                        : code === "CWMD"
                        ? opelInsigniaStation
                        : null
                    }
                    title="CAR"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {t("carDetails")}:
                    </Typography>
                    <Typography gutterBottom variant="h7" component="div">
                      <b>{t("vehicleType")}</b> {vehicleType(code)}
                    </Typography>
                    <Typography gutterBottom variant="h7" component="div">
                      <b>{t("fuelType")}</b> {checkFuelType(fuelType, code)}
                    </Typography>
                    <Typography gutterBottom variant="h7" component="div">
                      <b>{t("gearbox")}</b>{" "}
                      {[
                        "EDAV",
                        "MDAV",
                        "CTAV",
                        "RVAD",
                        "HDAE",
                        "CDAV",
                        "CDAD",
                        "CGAV",
                        "CGAD",
                        "DDAD",
                        "PDAD",
                        "SFAD",
                      ].includes(code)
                        ? t("automatic")
                        : t("manual")}
                    </Typography>
                    <Typography gutterBottom variant="h7" component="div">
                      <b>{t("seats")}</b> {seats}
                    </Typography>
                    <Typography gutterBottom variant="h7" component="div">
                      <b>{t("doors")}</b> {doors}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Box component="div">
                      <Button
                        sx={{ bottom: "10px" }}
                        variant="contained"
                        size="large"
                      >
                        <Link
                          smooth
                          to="#body"
                          onClick={() =>
                            setChoosedCar({
                              code: code,
                              brand: brand,
                              picture: picture,
                            })
                          }
                        >
                          {t("reserve")}
                        </Link>
                      </Button>
                    </Box>
                  </CardActions>
                  <CardActions>
                    <Box componentAct="div">
                      <Button
                        sx={{ bottom: "10px" }}
                        variant="contained"
                        size="large"
                      >
                        <Link
                          smooth
                          to="#contacts"
                          onClick={() =>
                            setQuoteMessage(
                              t("quoteMessage") + vehicleCodeChanger(code)
                            )
                          }
                        >
                          {t("requestQuote")}
                        </Link>
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Box>
            )
          )}
        </Carousel>
      </div>
    </>
  );
}

export default CarouselComponent;
